import gql from "graphql-tag";
export const QUERY_PROFILE = gql`
  query myAccount {
    myAccount {
      id
      email
      enable
      tokens {
        id
        name
        timestamp
        key
      }
      level
      timestamp
    }
  }
`;

export const USER_QUERY = gql`
  query getUserList($tokenOnly: Boolean = false) {
    getUserList {
      users {
        id
        email
        ...credentials @skip(if: $tokenOnly)
        ...tokenOnly @include(if: $tokenOnly)
      }
      totalCount
    }
  }

  fragment credentials on User {
    enable
    level
    timestamp
  }
  fragment tokenOnly on User {
    tokens {
      id
      name
      timestamp
      key
    }
  }
`;
export const EDIT_USER_MUTATION = gql`
  mutation editUser($id: ID!, $input: UserEditInput!) {
    editUser(id: $id, input: $input) {
      id
    }
  }
`;
export const RENEW_TOKEN_MUTATION = gql`
  mutation renewToken($id: ID!) {
    renewToken(id: $id) {
      id
    }
  }
`;

export const REMOVE_TOKEN_MUTATION = gql`
  mutation removeToken($id: ID!) {
    removeToken(id: $id)
  }
`;

export const CREATE_USER_MUTATION = gql`
  mutation createUser($input: UserInput!) {
    createUser(input: $input) {
      id
    }
  }
`;
export const UDPDATE_PROFILE_MUTATION = gql`
  mutation editMyAccount($input: UserEditInput!) {
    editMyAccount(input: $input) {
      id
    }
  }
`;
export const REPORTS_QUERY = gql`
  query getReports {
    getReports {
      reports {
        url
        name
        timestamp
        status
      }
      totalCount
    }
  }
`;
export const TRANSACTIONS_QUERY = gql`
  query getTransactionList(
    $begin: Date!
    $end: Date!
    $page: Int
    $limit: Int
    $search: String
  ) {
    getTransactionList(
      begin: $begin
      end: $end
      page: $page
      limit: $limit
      search: $search
    ) {
      transactions {
        id
        paymentId
        creator {
          name
          owner {
            email
          }
        }
        amount
        channel
        channelId
        timestamp
        barcodes
        histories {
          id
          status
        }
      }
      totalCount
    }
  }
`;
export const TRANSACTION_DETAIL_QUERY = gql`
  query getTransaction($paymentId: ID!) {
    getTransaction(paymentId: $paymentId) {
      id
      paymentId
      creator {
        owner {
          email
        }
        key
        name
      }
      amount
      channel
      channelId
      timestamp
      expiryDate
      barcodes
      reference
      icon
      callback
      histories {
        id
        status
        timestamp
      }
    }
  }
`;
export const BUILD_REPORT_MUTATION = gql`
  mutation buildReport($begin: Date!, $end: Date!) {
    buildReport(begin: $begin, end: $end)
  }
`;

export const TRANSACTION_PERFORMANCE = gql`
  query transactionPerformance {
    transactionPerformance {
      date
      performance {
        channel
        amount
        total
      }
    }
  }
`;
export const CREATE_TOKEN = gql`
  mutation createToken($label: String!) {
    createToken(label: $label) {
      id
    }
  }
`;
