import React, { createContext, useState, useContext } from 'react'

const Context = createContext()
export const ComponentProvider = props => {
  const [state, setState] = useState({
    loading: false,
    snackbar: {
      text: null,
      open: false
    }
  })
  const setLoading = (window.setLoading = loading =>
    setState(prev => ({ ...prev, loading })))

  const setSnackbar = (window.setSnackbar = text => {
    setState(prev => ({
      ...prev,
      snackbar: {
        text,
        open: true
      }
    }))
  })
  const closeSnackbar = () => {
    setState(prev => ({
      ...prev,
      snackbar: {
        text: null,
        open: false
      }
    }))
  }
  return (
    <Context.Provider
      value={[state, { setLoading, closeSnackbar, setSnackbar }]}
    >
      {props.children}
    </Context.Provider>
  )
}
export default () => useContext(Context)
