import React from 'react'
import Button from '@material-ui/core/Button'

export default ({
  history,
  code = 404,
  text = 'The page you are looking for is not available.'
}) => {
  const style = {
    color: '#aaa',
    marginBottom: 5
  }
  const back = () => {
    history.goBack()
  }
  return (
    <div className="text-center">
      <h1 className="mb-0">{code}</h1>
      <p style={style}>{text}</p>
      <Button
        variant="contained"
        className="mt-3"
        color="primary"
        onClick={back}
      >
        Back
      </Button>
    </div>
  )
}
