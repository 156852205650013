import React, { memo } from 'react'
import { Route } from 'react-router-dom'
import userContext from '../context/UserContext'
import ErrorPage from '../pages/404'

export default memo(props => {
  const { isLoggedIn, context } = userContext()
  const Component = props.component
  props = {
    ...props,
    component: null
  }
  return (
    <Route
      {...props}
      render={innerProps =>
        isLoggedIn && context.level === props.level ? (
          <Component {...innerProps} />
        ) : (
          <ErrorPage code="401" text="You have no access to this page." />
        )
      }
    />
  )
})
