import React from 'react'
import Component, { ComponentProvider } from './ComponentContext'
import User, { UserProvider } from './UserContext'

export default props => (
  <ComponentProvider>
    <UserProvider>{props.children}</UserProvider>
  </ComponentProvider>
)

export const ComponentContext = Component
export const UserContext = User
