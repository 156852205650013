import React, { memo } from 'react'
import { Route, Redirect } from 'react-router-dom'
import userContext from '../context/UserContext'

export default memo(props => {
  const { isLoggedIn } = userContext()
  const Component = props.component
  props = {
    ...props,
    component: null
  }
  return (
    <Route
      {...props}
      render={innerProps =>
        isLoggedIn ? (
          <Component {...innerProps} />
        ) : (
          <Redirect
            to={{ pathname: '/login', state: props.location.pathname }}
          />
        )
      }
    />
  )
})
