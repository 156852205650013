import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import ContextProvider from "./context";
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "@apollo/react-hooks";
import { GRAPHQL_ENDPOINT as uri } from "./static";

const client = new ApolloClient({
  uri,
  headers: {
    Authorization: localStorage.getItem("token")
  },
  onError({ graphQLErrors, networkError }) {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }) => {
        window.setSnackbar("Warning! " + message);
        window.setLoading(false);
        if (message === "Invalid Credentials") {
          localStorage.removeItem("token");
          window.location.reload();
        }
        return console.log(
          `[GraphQL error]: Message: ${message}, Path: ${path}, Location: `,
          locations
        );
      });

    if (networkError) console.log(`[Network error]: ${networkError}`);
  }
});
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "rgb(81, 153, 228)",
      contrastText: "white"
    },
    secondary: {
      main: "#ff9800",
      contrastText: "white"
    }
  }
});

if (process.env.NODE_ENV === "production") window.console.log = () => {};

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <ApolloProvider client={client}>
      <ContextProvider>
        <App />
      </ContextProvider>
    </ApolloProvider>
  </ThemeProvider>,
  document.getElementById("root")
);
