import React, { createContext, useState, useContext } from "react";

const Context = createContext();
export const UserProvider = props => {
  const [state, setState] = useState({
    id: null,
    email: "",
    enable: false,
    level: "",
    timestamp: null,
    bearerToken: "",
    tokens: []
  });
  const isAdmin = state.level === "ADMIN";
  const bearertoken = () => {
    if (state.bearerToken === "")
      state.bearerToken = localStorage.getItem("token");
    return state.bearerToken;
  };
  const setBearerToken = token => {
    localStorage.setItem("token", token);
    state.bearerToken = token;
  };
  const setContext = ctx => {
    setState(prev => ({ ...prev, ...ctx }));
  };
  const logout = () => {
    localStorage.removeItem("token");
    window.location.href =
      window.location.protocol + "//" + window.location.hostname;
  };
  return (
    <Context.Provider
      value={{
        context: state,
        setContext,
        isAdmin,
        isLoggedIn: !!bearertoken(),
        setBearerToken,
        logout
      }}
    >
      {props.children}
    </Context.Provider>
  );
};
export default () => useContext(Context);
