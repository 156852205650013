import React, { Fragment } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useQuery } from "@apollo/react-hooks";
import Loadable from "react-loadable";
import "./App.css";

import { QUERY_PROFILE } from "./query";
import { UserContext, ComponentContext } from "./context";

import Drawer from "./components/Drawer";
import AppBar from "./components/AppBar";
import PrivateRoute from "./components/PrivateRoute";
import AuthorizedRoute from "./components/AuthorizedRoute";

import { DRAWER_WIDTH } from "./static/component";

const loading = () => {
  window.setLoading = true;
  return null;
};
const render = (loaded, props) => {
  let Component = loaded.default;
  return <Component {...props} />;
};

const Login = Loadable({
  loading,
  render,
  loader: () => import(/* webpackChunkName: "Login" */ "./pages/Login"),
});
const Home = Loadable({
  loading,
  render,
  loader: () => import(/* webpackChunkName: "Home" */ "./pages/Home"),
});
const Profile = Loadable({
  loading,
  render,
  loader: () => import(/* webpackChunkName: "Profile" */ "./pages/Profile"),
});
const Users = Loadable({
  loading,
  render,
  loader: () => import(/* webpackChunkName: "Users" */ "./pages/Users"),
});
const Token = Loadable({
  loading,
  render,
  loader: () => import(/* webpackChunkName: "Token" */ "./pages/Token"),
});
const Download = Loadable({
  loading,
  render,
  loader: () => import(/* webpackChunkName: "Download" */ "./pages/Download"),
});
const Transactions = Loadable({
  loading,
  render,
  loader: () =>
    import(/* webpack)ChunkName: "Transactions" */ "./pages/Transactions"),
});
const NotFound = Loadable({
  loading,
  render,
  loader: () => import(/* webpackChunkName: "404" */ "./pages/404"),
});

const App = () => {
  const { isLoggedIn, setContext } = UserContext();
  const [context, { closeSnackbar }] = ComponentContext();

  const { data } = useQuery(QUERY_PROFILE, {
    skip: !isLoggedIn,
    onCompleted() {
      setContext(data ? data.myAccount : null);
    },
  });

  return (
    <Router>
      <Fragment>
        {!isLoggedIn || (
          <Fragment>
            <AppBar />
            <Drawer />
          </Fragment>
        )}
        <div
          className="wrapper"
          style={{ marginLeft: isLoggedIn ? DRAWER_WIDTH + "px" : 0 }}
        >
          <Switch>
            <Route path="/login" component={Login} />
            <PrivateRoute path="/profile" component={Profile} />
            <AuthorizedRoute path="/users" level="ADMIN" component={Users} />
            <AuthorizedRoute
              path="/dashboard"
              exact
              level="ADMIN"
              component={Home}
            />
            <PrivateRoute path="/token" component={Token} />
            <PrivateRoute path="/download" component={Download} />
            <PrivateRoute path="/" component={Transactions} />
            <PrivateRoute path="*" component={NotFound} />
          </Switch>
        </div>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={context.snackbar.open}
          autoHideDuration={6000}
          onClose={closeSnackbar}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">{context.snackbar.text}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={closeSnackbar}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </Fragment>
    </Router>
  );
};

export default App;
