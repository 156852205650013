import React from "react";
import { withRouter, NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Dashboard from "@material-ui/icons/Dashboard";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import GetAppIcon from "@material-ui/icons/GetApp";
import PeopleIcon from "@material-ui/icons/People";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import logo from "../assets/telin_small.png";

import { UserContext, ComponentContext } from "../context";
import { DRAWER_WIDTH } from "../static/component";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
    overflow: "hidden",
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
    overflowX: "hidden",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
  drawerHeader: {
    display: "flex",
    flexFlow: "column wrap",
    alignItems: "center",
  },
  avatar: {
    width: 150,
    maxWidth: "100%",
    objectFit: "contain",
    marginTop: 8,
  },
  greet: {
    color: "#aaa",
    marginBottom: 5,
    textAlign: "center",
  },
  active: {
    backgroundColor: "#eee",
  },
}));

const menus = [
  {
    name: "Transactions",
    url: "/",
    icon: AccountBalanceWalletIcon,
    visibility: true,
  },
  // {
  //   name: "Dashboard",
  //   url: "/dashboard",
  //   icon: Dashboard,
  //   visibility: "ADMIN",
  // },
  { name: "Download", url: "/download", icon: GetAppIcon, visibility: true },
  {
    name: "Token",
    url: "/token",
    icon: ConfirmationNumberIcon,
    visibility: true,
  },
  { name: "User", url: "/users", icon: PeopleIcon, visibility: "ADMIN" },
];

export default withRouter(({ location }) => {
  const { context: user } = UserContext();
  const [{ loading }] = ComponentContext();
  const classes = useStyles();
  const currentPath = location.pathname;
  console.log(user);
  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <img src={logo} alt="Avatar" className={classes.avatar} />
        <h2 color="primary" className="mb-0 mt-0">
          Payment Gateway
        </h2>
        <p className={[classes.greet, "mt-1"].join(" ")}>
          {user.enable
            ? null
            : !loading && (
                <span className="text-danger">
                  This account is disabled. Please Contact Administrator
                </span>
              )}
        </p>
      </div>
      <List className="mt-3">
        {/* <p className={classes.navigation}>Navigation</p> */}
        {menus
          .filter(
            (el) => el.visibility === user.level || el.visibility === true
          )
          .map((menu, index) => (
            <NavLink to={menu.url} key={menu.name} className="navlink">
              <ListItem
                button
                className={[
                  classes.icon,
                  currentPath === menu.url ? classes.active : "",
                ].join(" ")}
              >
                <ListItemIcon>
                  <menu.icon />
                </ListItemIcon>
                <ListItemText primary={menu.name} />
              </ListItem>
            </NavLink>
          ))}
      </List>
    </Drawer>
  );
});
