import React, { memo, useRef, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Icon from "@material-ui/core/Icon";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import LinearProgress from "@material-ui/core/LinearProgress";
import { ComponentContext, UserContext } from "../context";

import { DRAWER_WIDTH } from "../static/component";

const useStyles = makeStyles(theme => ({
  title: {
    flex: 1,
    color: "white"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    marginLeft: DRAWER_WIDTH,
    position: "relative"
  },
  email: {
    color: "white",
    textTransform: "lowercase"
  },
  link: {
    color: "#333",
    textDecoration: "none"
  }
}));

export default memo(
  withRouter(({ location }) => {
    const [emailToggle, setEmailToggle] = useState(null);
    const anchorRef = useRef(null);
    const handleToggle = e => setEmailToggle(e.currentTarget);
    const handleClose = () => setEmailToggle(null);

    const [context] = ComponentContext();
    const { context: user, logout: logoutContext, isLoggedIn } = UserContext();

    const logout = () => {
      handleClose();
      logoutContext();
    };

    const classes = useStyles();

    const arr = location.pathname.split("/");
    const currRoute =
      arr[arr.length - 1] === ""
        ? "Transaction"
        : arr[arr.length - 1]
            .split("")
            .map((el, idx) => (idx === 0 ? el.toUpperCase() : el))
            .join("");
    return (
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            {currRoute}
          </Typography>
          <Button
            ref={anchorRef}
            className={classes.email}
            aria-controls="menu-list-grow"
            aria-haspopup="true"
            onClick={handleToggle}
          >
            {user.email} <ArrowDropDownIcon />
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={emailToggle}
            keepMounted
            open={Boolean(emailToggle)}
            onClose={handleClose}
          >
            {user.enable && (
              <Link to="/profile" className={classes.link}>
                <MenuItem>
                  <Icon className="mr-2">person</Icon>
                  Update Profile
                </MenuItem>
              </Link>
            )}
            <MenuItem onClick={logout}>
              <Icon className="mr-2">logout</Icon> Logout
            </MenuItem>
          </Menu>
        </Toolbar>
        {context.loading && <LinearProgress className="loading-bar" />}
      </AppBar>
    );
  })
);
